
.bp3-button.bp3-large, .bp3-large .bp3-button {
    font-size: 14px;
    box-shadow: 0 4px 6px 0 rgba(12,34,53,0.07);
}

.btn-form {
    line-height: 2em;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 4px;
}

.btn-form.bp3-minimal {
    text-transform:none;
    font-weight:normal;
}

.bp3-button.btn-border {
    box-shadow: 0 0 0px 1px #d4d4d4 !important;
}


.bp3-button.bp3-intent-primary {
    background-color: #008CFF;
    box-shadow: 0 4px 6px 0 rgba(12,34,53,0.07);
}

.bp3-button.active, .bp3-button.active .bp3-icon {
    color: #008CFF;
}

.bp3-button.bp3-intent-primary:hover {
    box-shadow: 0 4px 6px 0 rgba(12,34,53,0.07);
    background-color: #0070CC
}

.bp3-button.bp3-intent-danger {
    background-color: #EC2D6D;
}

    .bp3-button.bp3-intent-danger:hover {
        box-shadow: 0 4px 6px 0 rgba(12,34,53,0.07);
        background-color: #BD2457
    }

    .bp3-button.bp3-intent-danger:disabled, .bp3-button.bp3-intent-danger.bp3-disabled {
        background-color: rgba(236, 45, 109, 0.5);
    }

    .bp3-button.bp3-intent-secondary {
        background-color: #E9ECEF;
    }

.bp3-button.bp3-intent-secondary:hover {
    background-color:#DCDFE2;
}

.bp3-button.bp3-intent-primary:disabled, .bp3-button.bp3-intent-primary.bp3-disabled {
    background-color: rgba(0, 140, 255, 0.5);
}

.btn-inline-text {
    background: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
    text-decoration: none;
    color: #106ba3;
}

.btn-inline-text:hover {
    text-decoration: underline;
}

.button-row .bp3-button {
    margin-right: 15px;
}

.button-row .bp3-divider {
    display:inline;
}

.button-row .bp3-icon {
    color: #5c7080;
}

.button-row .bp3-popover-wrapper {
    margin-right: 10px;
}

.button-row .bp3-popover-wrapper:last-child {
    margin-right:0;
}

.button-row .dropdown-container {
    margin-right: 15px;
}
 
.button-row .dropdown-container .bp3-button {
    margin-right: 0;
}
 
.button-row > .bp3-popover-wrapper .dropdown-container .bp3-button, .button-row > .bp3-popover-wrapper .dropdown-container {
    margin-right: 0;
}

.button-row-small .bp3-button {
    margin-right: 5px;
}

.button-row .bp3-button:last-child, .button-row-small .bp3-button:last-child {
    margin-right: 0;
}

.button-row-placeholder {
    min-height: 40px;
}

.save-loader {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}

.button-row .save-loader {
    margin-left: 0;
    padding-right: 15px;
}

.bp3-dialog-footer-actions .save-loader {
    display:flex;
}

.save-loader .bp3-spinner-track {
    stroke: #66BAFF;
}

.save-loader .bp3-spinner-head {
    stroke: #66DDD1;
}

.bp3-button.bp3-minimal.bp3-intent-danger {
    color: #EC2D6D;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 11px;
    background: none;
}

.bp3-button.bp3-button.bp3-minimal.none {
    color: #858585;
}

.bp3-button.bp3-button.bp3-minimal.none:hover {
    background: rgba(94, 94, 94, 0.1);
}


.bp3-button.bp3-minimal.bp3-intent-danger:hover {
    background: rgba(236, 45, 109, 0.1);
    color: #EC2D6D;
}

.bp3-button.bp3-minimal.bp3-intent-primary {
    color: #008CFF;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 11px;
    background: none;
}

.bp3-button.bp3-minimal.bp3-intent-primary:hover {
    background: rgba(0, 140, 255, 0.1);
    color: #008CFF;
}

.bp3-button.bp3-minimal.bp3-intent-primary.bp3-disabled:hover {
    background: none;
    color: rgba(92, 112, 128, 0.6);
}
  
.bp3-button.bp3-minimal.bp3-intent-default {
    color: #5f5f5f;
    background: none;
}

.bp3-button.bp3-minimal.bp3-intent-default:hover {
    background: rgba(162, 162, 162, 0.1);
    color: #5f5f5f;
}

.bp3-button.bp3-minimal.bp3-intent-default.bp3-disabled:hover {
    background: none;
    color: rgba(92, 112, 128, 0.6);
}

.bp3-button.bp3-minimal.bp3-intent-warning {
    color: #FF7454;
}

.bp3-button.bp3-minimal.bp3-intent-warning:hover {
    background: rgb(255, 116, 84, 0.1);
    color: #FF7454;
}

.bp3-button.bp3-minimal.bp3-intent-secondary {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 11px;
    background: none;
    color: #737373;
}

.bp3-button.bp3-minimal.bp3-intent-secondary:hover {
    background: rgba(162, 162, 162, 0.1);
}

.bp3-button.key-button
{
    background-color: #005499;
    color: #ffffff;
}
.bp3-button.key-button:hover
{
    background-color: #002A53;
}

@media only screen and (max-width: 768px) {
    .button-row-stacked-mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .button-row-stacked-mobile.button-row .bp3-button {
        margin-bottom: 15px;
    }
}