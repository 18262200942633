.calendar-week {
    max-width: 1430px;
    display: flex;
    flex-wrap: wrap;
}

.calendar-week-odd {
    background-color: #F5F5F5;
}

.calendar-loading {
    width: 1430px;
    height: 400px;
}

.day-of-week-header{
font-size: 11px;
    font-weight: 600;
    padding: 5px;
    width: 200px;
}

.asset-calendar-dashboard {
    max-width: 1430px;
    background-color: #f0f0f0;
    text-align: left;
    padding: 5px;
}

.asset-calendar-dashboard-header-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.asset-calendar-dashboard-header {
    font-weight: 600;
    font-size: 11px;
    letter-spacing: 0px;
    opacity: 1;
    color: #212026;
}

.asset-calendar-dashboard-date {
    color: #000;
    font-weight: 600;
    font-size: 16px;
    margin: 0;
    padding: 0;
}

.asset-calendar-dashboard-navigation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 60px;
    justify-content: center;
}

.asset-calendar-dashboard-navigation-buttons {
    display: flex;
    gap: 10px;
    margin: 0;
    padding: 0;
}

.asset-calendar-dashboard-inner-card {
    padding: 5px;
}

.asset-calendar-dashboard-table {
    background-color: white;
    width: 100%;
    table-layout: fixed;
    height: 47px;
}

    .asset-calendar-dashboard-table th,
    .asset-calendar-dashboard-table td {
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
    }

        .asset-calendar-dashboard-table th:nth-child(-n+5),
        .asset-calendar-dashboard-table td:nth-child(-n+5) {
            width: 120px;
        }

        .asset-calendar-dashboard-table th:nth-child(6),
        .asset-calendar-dashboard-table td:nth-child(6) {
            width: 80px;
        }

        .asset-calendar-dashboard-table th:nth-child(7),
        .asset-calendar-dashboard-table td:nth-child(7),
        .asset-calendar-dashboard-table th:nth-child(8),
        .asset-calendar-dashboard-table td:nth-child(8) {
            width: 130px;
        }

.asset-calendar-dashboard-table-row {
    text-align: left;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #212026;
    opacity: 1;
}

.asset-calendar-dashboard-upload-table {
    width: 100%;
    table-layout: fixed;
    height: 47px;
}

    .asset-calendar-dashboard-upload-table th:nth-child(1),
    .asset-calendar-dashboard-upload-table td:nth-child(1) {
        width: 200px;
    }

.vehicle-icon-calendar {
    margin-right: 4px;
    display: inline-block;
    height: 16px;
    width: 16px;
}


.asset-calendar-dashboard-person-icon {
    color: #008CFF !important;
    font-weight: 600;
    font-size: 11px;
    display: block;
    align-items: flex-start;
    margin: 2px;
    text-decoration: none !important;
    text-align: center;
}

    .asset-calendar-dashboard-person-icon:hover {
        color: #005499 !important;
    }

    .asset-calendar-dashboard-person-icon i {
        margin-right: 4px;
        color: inherit;
    }

.asset-calendar-dashboard-person-icon-linkless {
    color: black !important;
    font-weight: 600;
    font-size: 11px;
    display: block;
    align-items: flex-start;
    margin: 2px;
    text-decoration: none !important;
    text-align: center;
}
    .asset-calendar-dashboard-person-icon-linkless i {
        margin-right: 4px;
        color: inherit;
    }

.asset-calendar-dashboard-driver-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    height: 47px;
    text-align: center !important;
    overflow: auto;
}

.asset-calendar-dashboard-button {
    background-color: white !important;
    color: #212026 !important;
    text-align: center !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    letter-spacing: 0px !important;
    opacity: 1 !important;
    margin-top: 7px;
    padding: 0px 30px !important;
    border: none !important;
    background-image: none !important;
    text-transform: none !important;
}

    .asset-calendar-dashboard-button:hover {
        background-color: #0C223526 !important;
        background-image: none !important;
    }

    .asset-calendar-dashboard-button.selected {
        background-color: #D1D1D1 !important;
        background-image: none !important;
    }