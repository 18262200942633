.notification-inline {
    margin-bottom: 30px;
}

.notification-inline.closable {
    padding-right: 40px;
}

.notification-inline.bp3-intent-danger {
    background: #EC2D6D;
    color: #FFFFFF;
}

.notification-inline.bp3-intent-warning {
    background: #FF7454;
    color: #FFFFFF;
}

.notification-inline.bp3-intent-danger .bp3-icon:first-child,
.notification-inline.bp3-intent-primary .bp3-icon:first-child,
.notification-inline.bp3-intent-success .bp3-icon:first-child,
.notification-inline.bp3-intent-warning .bp3-icon:first-child {
    color: #FFFFFF;
}

.notification-inline.bp3-intent-primary {
    background: #5A70F3;
    color: #FFFFFF;
}

.notification-inline.bp3-intent-success {
    background: #00C6B2;
    color: #FFFFFF;
}

.notification-inline .btn-close {
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -15px;
    color: #FFFFFF;
}

.notification-inline .btn-inline-text {
    color: #FFFFFF;
    font-weight: bold;
}

.bp3-dialog-close-button {
    margin-right: 10px;
}

.notification-inline .bp3-button.bp3-minimal:hover {
    background: none;
}

.bp3-overlay.bp3-overlay-scroll-container,
.bp3-portal.bp3-overlay-scroll-container {
    z-index: 2000;
}

.bp3-dialog {
    background: #FFFFFF;
    min-width: 860px;
}

.bp3-dialog-header {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    box-shadow: none;
}

.bp3-dialog-body {
    margin: 20px;
}

.bp3-dialog-body p {
    line-height: 1.5em;
    color: #212026;
}

.bp3-alert {
    max-width: 600px
}

.wide {
    width: 50%;
}

.extra-wide {
    width: 85%;
}

.full-screen {
    width: 90%;
    height: 90vh;
    overflow-y: auto;
}

.fixed-height {
    height: 90vh;
}

.dirty-data-container h4 {
    border-bottom: 1px solid #D4D4D4;
    padding-bottom: 15px;
    font-size: 19px;
    font-weight: 600;
}

.bp3-overlay .media-player {
    user-select: all;
    pointer-events: all;
}

.bp3-overlay .media-player video {
    width: 1000px;
    max-width: 95%;
    z-index: 5000;
}

.bp3-portal.media-player-overlay {
    z-index: 2500;
}

.bp3-dialog-header svg {
    height: 30px;
    width: 30px;
}

.bp3-toast-container {
    z-index: 9000;
}

.short-width {
    max-width: 500px;
}