.align-tabs-and-date {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
}
.day-detail-modal {
    width: 95%;
    max-width: 1200px;
    height: 90vh;
    position: relative;
}