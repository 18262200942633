.preferences-content p {
    margin-bottom: 10px;
}

.preferences-modal-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 70vh;
}

.table-container {
    flex: 1;
    position: relative;
    overflow-y: auto;
    max-height: 40vh;
    min-height: 100px;
}

.table-container table {
    width: 100%;
    border-collapse: collapse;
}

.table-container thead {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}

.table-container th {
    text-align: left;
}

.table-container th:last-child {
    text-align: right;
}

.table-container .bp3-table-column-header-name {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.checkbox-cell {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.table-container tr {
    height: auto !important;
    min-height: 30px !important;
}

.table-container td {
    padding: 2px 10px !important;
    vertical-align: middle !important;
    line-height: 1.2 !important;
}

.preferences-modal-body .button-row {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.preferences-modal-body .form-field {
    margin-bottom: 10px;
}

.preferences-modal-body .cancel-button {
    background-color: #E0E0E0;
    box-shadow: none;
}

    .preferences-modal-body .cancel-button:hover {
        background-color: #CBCBCB;
    }


.preferences-modal-body .bp3-control {
    margin-top: 5px;
    margin-bottom: 5px;
    padding-right: 5px;
}