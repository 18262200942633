#top-bar {
    height: 60px;
    width: 100%;
    background: #0C2235;
    position: fixed;
    top:0;
    left:0;
    padding: 0 20px;
    z-index:1500;
}

#top-bar.developer {
    background: #5A70F3;
}

#top-bar.test {
    background: #EC2D6D;
}

#top-bar.UAT, #top-bar.uat {
    background: #008CFF;
}

#top-bar .bar-layout {
    width:100%;
    height:100%;
    display:flex;
    align-items: center;
    justify-content:space-between;
}

#top-bar .bp3-button, #top-bar .bp3-icon {
    color: #FFFFFF;
}

#top-bar #logo {
    height: 40px;
    margin-left:40px;
}

#top-bar .top-bar-item {
    display: inline-block;
    margin-left:20px;
    color: #FFFFFF;
    font-size:14px;
    vertical-align:middle;
}

    #top-bar #user-menu .bp3-icon.bp3-icon-person {
        display: none;
    }

    #top-bar #user-menu .bp3-icon.bp3-icon-chevron-down {
        margin: 0 2px;
    }

#top-bar .environment-identifier {
    font-weight: 600;
    text-transform: capitalize;
}


#left-bar {
    position: fixed;
    top: 60px;
    left: 0;
    bottom: 0;
    background: #0c2235;
    z-index:9;
}

    #left-bar > ul {
        margin:0;
    }

#left-bar ul {
    padding:0;
    list-style:none;
}

    #left-bar ul li {
        position:relative;
    }

        #left-bar ul li.active {
            background-color: #008CFF !important;
        }

            #left-bar ul li.active.external {
                background-color: #00ccb8 !important;
            }

        #left-bar ul li:hover {
            background: #384f68;
        }

        #left-bar ul li .menu-icon {
            width: 44px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
        }


            #left-bar ul li .menu-icon .custom-icon, #left-bar ul li .menu-icon .fa {
                color: #ffffff;
                font-size: 20px;
            }

        #left-bar ul li.external .menu-icon .custom-icon, #left-bar ul li.external .menu-icon .fa {
            color: #00ccb8;
        }

        #left-bar ul li.external.active .menu-icon .custom-icon, #left-bar ul li.external.active .menu-icon .fa {
            color: #ffffff;
        }

        #left-bar ul li .menu-popout {
            position: absolute;
            left: 100%;
            top: 0;
            background: #384f68;
            padding: 0;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            display: none;
        }


        #left-bar ul li:hover .menu-popout {
            display:block;
        }

    #left-bar ul li .menu-popout h4 {
        color:#ffffff;
        font-weight:600;
        margin-bottom:0;
        font-size:14px;
        white-space:nowrap;
        padding:13px 20px;
    }

        #left-bar ul li .menu-popout ul {
            margin:0;
            padding:0;
            margin-top:0px;
            border-bottom-right-radius:4px;
            overflow:hidden;
        }

        #left-bar ul li .menu-popout ul li a {
            color:#ffffff;
            text-decoration:none;
            display:block;
            white-space:nowrap;
            font-size:12px;
            padding:10px 20px;
        }

            #left-bar ul li .menu-popout ul li a:hover {
                background: #0c2235;
                text-decoration:none;
            }

    #left-bar .menu-popout ul li:last-child a {
        padding-bottom:15px;
    }

.item-expandable {
    max-height: 400px;
    overflow: auto;
    width: fit-content;
}

.item-expandable.expanded {
    max-height:initial;
}

.item-expandable.stretched {
    width: 100%;
}

.collapsable-item {
    max-width:850px;
    margin:30px 0;
}

.collapsable-item.full-width {
    max-width: 100%;
    margin-top: 15px;
}

.collapsable-header {
    background: #0C2235;
    padding: 15px;
    border-radius: 4px;
    color: #FFFFFF;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items:center;
}

.collapsable-icon-status {
    margin-right:10px;
    vertical-align:middle;
}

.collapsable-header .bp3-icon {
    color: #FFFFFF !important;
}

.collapsable-content {
    padding:15px;
}

.collapsable-icons {
    flex-shrink: 0;
    padding-left: 15px;
    display: flex;
    align-items: center;
}

.collapsable-metadata {
    padding-right: 15px;
    font-weight: 100;
}

.collapsable-metadata .metadata-status {
    background: #FFFFFF;
    border-radius: 5px;
    padding-left: 10px;
}

.collapsable-metadata .metadata-status .status-block {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.collapsable-item-light {
    max-width: 850px;
    margin: 30px 0;
}

.collapsable-header-lightMode {
    background: #e8e8e8;
    padding: 15px;
    /*border-radius: 4px;*/
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #0c0c0c;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.collapsable-content-lightMode {
    padding: 20px;
    border: solid 2px #e8e8e8;
    border-top: 0px;
}

@media only screen and (max-width: 768px) {
    #top-bar #user-menu .bp3-icon.bp3-icon-person {
        display: block;
        margin-right: 0;
    }
    #top-bar #user-menu .bp3-button-text {
        display: none;
    }

    #top-bar .top-bar-item {
        margin-left:0;
    }
}
.alert-type-icon {
    min-width: 20px;
}

.alert-container .inline-item {
    margin-right:0;
    position:relative;
}

.alert-container .inline-item button {
    padding-top:15px;
}

.alert-container i {
    font-size: 20px;
}

.alert-count {
    position:absolute;
    top: 5px;
    left:50%;
    font-size:12px;
    font-weight:700;
}

.alert-count-load {
    position: absolute;
    top: 5px;
    left: 50%;
    font-weight: 700;
    background: #008cff;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}