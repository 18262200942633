.location-listing-container {
    width: 220px;
}

.location-items {
    margin-right: 10px;
}

.draggable-list-item {
    user-select: none;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 5px;
    margin-bottom: 5px;
    background-color: #FFFFFF;
    font-weight: 600;
    box-shadow: 0 1px 6px 0 rgb(12 34 53 / 18%) !important;
    position: relative;
    padding-left: 15px;
    width: 190px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space:nowrap;
}

.draggable-item-duplicate {
    color: #4c5960;
}

    .draggable-list-item.tacho-vis-loc {
        background-color: #FFFFFF;
        background-image:none;
    }

.draggable-list-item:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 100%;
    background: #CCCCCC;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}

.draggable-list-item.inactive-loc {
    color:#8d8d8d;
}

.draggable-list-item.tacho-loc:before {
    background: #00C6B2;
}

.draggable-list-item.vis-loc:before {
    background: #5A70F3;
}

.draggable-list-item.pcn-loc:before {
    background: #EC2D6D;
}

.draggable-list-item.audit-loc:before {
    background: #FF7454;
}

.draggable-list-item.tacho-vis-loc:before {
    background-image: linear-gradient(0deg, #00C6B2 0% 50%, #5A70F3 50% 100%);
}

.draggable-list-item.tacho-audit-loc:before {
    background-image: linear-gradient(0deg, #00C6B2 0% 50%, #FF7454 50% 100%);
}

.draggable-list-item.tacho-pcn-loc:before {
    background-image: linear-gradient(0deg, #00C6B2 0% 50%, #EC2D6D 50% 100%);
}

.draggable-list-item.vis-pcn-loc:before {
    background-image: linear-gradient(0deg, #5A70F3 0% 50%, #EC2D6D 50% 100%);
}

.draggable-list-item.vis-audit-loc:before {
    background-image: linear-gradient(0deg, #5A70F3 0% 50%, #FF7454 50% 100%);
}

.draggable-list-item.audit-pcn-loc:before {
    background-image: linear-gradient(0deg, #FF7454 0% 50%, #EC2D6D 50% 100%);
}

.draggable-list-item.tacho-vis-audit-loc:before {
    background-image: linear-gradient(0deg, #00C6B2 0% 33%, #5A70F3 33% 66%, #FF7454 66% 100%);
}

.draggable-list-item.tacho-vis-pcn-loc:before {
    background-image: linear-gradient(0deg, #00C6B2 0% 33%, #5A70F3 33% 66%, #EC2D6D 66% 100%);
}

.draggable-list-item.tacho-audit-pcn-loc:before {
    background-image: linear-gradient(0deg, #00C6B2 0% 33%, #FF7454 33% 66%, #EC2D6D 66% 100%);
}

.draggable-list-item.vis-audit-pcn-loc:before {
    background-image: linear-gradient(0deg, #5A70F3 0% 33%, #FF7454 33% 66%, #EC2D6D 66% 100%);
}

.draggable-list-item.tacho-vis-audit-pcn-loc:before {
    background-image: linear-gradient(0deg, #00C6B2 0% 25%, #5A70F3 25% 50%, #FF7454 50% 75%, #EC2D6D 75% 100%);
}


    .draggable-list-item-placeholder {
        opacity: 0.5;
    }

.draggable-list-item-fixed {
    transform: none !important;
}

.draggable-list-item-duplicate {
    padding-right: 30px;
}

.draggable-list-item.draggable-list-item-dragged {
    display: inline-block;
    width:auto !important;
}

.list-item-duplicate {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -9px;
}
