div.dropdown-preamble {
    position: relative;
    display: inline-block;
    padding: 8px;
    max-width: 380px;
}
div.dropdown-preamble h4 {
    font-weight: bold;
}
div.dropdown-preamble p {
    color: #666;
}
span.dropdown-container {
    margin-right: 10px;
}