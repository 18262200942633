.data-display-table-columns,
.data-display-table-rows .data-display-table-field {
    display: flex;
    flex-wrap: wrap;
}

.data-display-table-columns > *,
.data-display-table-field > * {
    flex-basis: 100%;
    flex-shrink: 0;
    flex-grow: 0;
}

.data-display-table-columns {
    padding-top: 15px;
}

.data-display-table-rows .data-display-table-field {
    margin: 0;
    padding: 15px;
}

.data-display-table-field-value.data-display-table-additional-field-value {
    padding-top: 0;
}

.data-display-table-field-header.data-display-table-additional-field-header {
    padding-top: 30px;
}

.data-display-table-additional-field-header:nth-child(2n) {
    padding-top: 0;
}

.data-display-table-no-value .data-display-table-field-header:first-child {
    flex-basis: 100%;
}

@media only screen and (min-width: 768px) {
    .data-display-table-columns > *,
    .data-display-table-field > * {
        flex-basis: 50%;
    }

    .data-display-table-columns.data-display-table-columns-3 > *,
    .data-display-table-field.data-display-table-columns-3 > * {
        flex-basis: 33%;
    }

    .data-display-table-columns.data-display-table-columns-4 > *,
    .data-display-table-field.data-display-table-columns-4 > * {
        flex-basis: 25%;
    }

    .data-display-table-columns > :nth-child(odd) {
        padding-right: 15px; 
    }
    
    .data-display-table-columns > :nth-child(even) {
        padding-left: 15px; 
    }

    .data-display-table-rows .data-display-table-field-header {
        margin: 0;
        padding-right: 30px;
    }

    .data-display-table-rows .data-display-table-field-value {
        padding-left: 15px;
    }

    .data-display-table-field-header.data-display-table-additional-field-header,
    .data-display-table-field-value.data-display-table-additional-field-value {
        padding-top: 15px;
    }

    .data-display-table-field-header.data-display-table-additional-field-header {
        font-weight: 100;
    }
}

.data-display-table-field-header {
    font-weight: 600;
}

.data-display-table-rows .data-display-table-field:nth-child(odd) {
    background-color: #F5F7F9;
}

.data-display-table-image-field {
    padding-bottom: 15px;
}

.data-display-table-image-field img {
    max-width: 100%;
    max-height: 100%;
}

.data-display-table-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    box-shadow: 0 1px 6px 0 rgb(12 34 53 / 18%);
    border-radius: 4px;
}

.data-display-table-field-value .form-field {
    margin-bottom: 0;
}