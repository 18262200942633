.form-field {
    margin-bottom: 35px;
}

.form-row .form-field {
    display:inline-block;
    margin-right:10px;
}

.form-row.outer-validation {
    margin-bottom: 15px;
}

.form-row .form-field:last-child {
    margin-right: 0;
}

.form-row.field-combination .helper-text,
.form-row.field-combination h4 {
    flex-basis: 100%;
}

.form-row.field-combination h4 {
    margin-bottom: 20px;
}

.form-row.outer-validation .form-field {
    margin-bottom: 0;
}

.form-field-readonly-value {
    font-size: 14px;
}

.inline-item .form-field {
    margin-bottom: 0;
}

.fluid-item .form-field input[type=text] {
    min-width:10px;
}

.form-field.has-helper h4 {
    margin-bottom: 5px;
}

.form-field h4 {
    margin-bottom: 20px;
}

.form-field .helper-text, .form-row.field-combination .helper-text {
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 14px;
}

.form-row.field-combination .helper-text.bp3-intent-danger {
    margin-bottom: 35px;
}

.form-field .helper-text.bp3-intent-danger, .form-row .helper-text.bp3-intent-danger {
    color: #ec2d6d;
}

.form-group .form-field {
    margin-bottom: 15px;
}

    .form-group .form-field:last-child {
        margin-bottom: 35px;
    }

.helper-text {
    margin-bottom: 30px;
    line-height: 1.5em;
    color: #737373;
}

.helper-text p:last-child {
    margin-bottom: 0;
}

/** INPUTS **/

.form-field input[type=text], .form-field textarea, .form-field input[type=password] {
    width: 100%;
}

    .form-field input[type=text].small, .form-field input[type=password].small, .form-field .small input[type=text] {
        max-width: 135px;
        min-width: 135px;
    }

    .form-field input[type=text].medium, .form-field input[type=password].medium, .form-field .medium input[type=text] {
        max-width: 195px;
        min-width: 195px;
    }

.form-field input[type=text], .form-field input[type=password] {
    max-width: 270px;
    min-width: 270px;
}

.inline-items-fill .inline-item .form-field .bp3-input-group > input[type=text] {
    max-width: 100%;
}

.col.side-col .form-field input[type=text] {
    max-width: 280px;
    min-width: 280px;
}

.form-field-full .form-field input[type=text], .form-field-full .form-field input[type=password] {
    max-width: 100%;
}

.form-field .bp3-input-group .bp3-icon svg {
    width:14px;
    height:14px;
}

.form-field .bp3-input-group.wide input[type=text],
.form-field .bp3-input-group.wide input[type=password] {
    max-width: 500px;
    min-width: 500px;
}

.form-field .bp3-input-group.really-wide input[type=text],
.form-field .bp3-input-group.really-wide input[type=password] {
    max-width: 750px;
    min-width: 750px;
}

.form-field .bp3-input-group.fill input[type=text],
.form-field .bp3-input-group.fill input[type=password] {
    max-width: 100%;
}

.form-field textarea {
    max-width: 500px;
    height: 80px;
    resize: none;
}

.form-text-counter {
    float: right;
    margin-top: 5px;
    font-size: 13px;
    color: #D4D4D4;
}

.wide {
    max-width: unset !important;
}

/** RADIO **/

.form-field .bp3-control .bp3-control-indicator {
    background: #ffffff;
    box-shadow: inset 0 0 0 1px #D4D4D4, inset 0 -1px 0 #D4D4D4;
    -webkit-box-shadow: inset 0 0 0 1px #D4D4D4, inset 0 -1px 0 #D4D4D4;
}

.form-field .bp3-control:hover .bp3-control-indicator {
    background: #ffffff;
}

.form-field .bp3-control:hover input:disabled ~ .bp3-control-indicator {
    background: rgba(206, 217, 224, 0.5);
}

.form-field .bp3-control input:checked ~ .bp3-control-indicator, .bp3-control.bp3-checkbox input:indeterminate ~ .bp3-control-indicator {
    background-color: #008CFF;
    background-image: none;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.bp3-control.bp3-checkbox input:indeterminate ~ .bp3-control-indicator {
    background-color: #737373;
}

.bp3-control.bp3-checkbox .bp3-control-indicator + p {
    margin-top: 0;
    display: inline-block;
    vertical-align: text-top;
}

.form-field .bp3-control:hover input:checked ~ .bp3-control-indicator, .bp3-control.bp3-checkbox:hover input:indeterminate ~ .bp3-control-indicator {
    background-color: #008CFF;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.bp3-control.bp3-checkbox:hover input:indeterminate ~ .bp3-control-indicator {
    background-color: #737373;
}

.form-field .bp3-control input:disabled:checked ~ .bp3-control-indicator {
    opacity:0.4;
}

.form-field-switcher .bp3-switch {
    min-width: 330px;
}

.inline-item .form-field-switcher .bp3-switch.bp3-align-right {
    min-width: 0;
}

.bp3-breadcrumb, .bp3-breadcrumbs-collapsed {
    color: #212026;
}

a.bp3-breadcrumb {
    color: #008CFF;
}

.form-field .bp3-select-popover {
    max-height:initial;
}

.form-field .bp3-select-popover .bp3-menu {
    max-height: 300px;
    overflow: auto;
}

.bp3-select-popover {
    background:#ffffff;
}

.bp3-select-popover .bp3-input-group {
    margin: 10px;
}



.form-field.bp3-html-select select, .form-field .bp3-select select {
    height: 36px;
    background: #ffffff;
    box-shadow: inset 0 0 0 1px #D4D4D4, inset 0 -1px 0 #D4D4D4;
    -webkit-box-shadow: inset 0 0 0 1px #D4D4D4, inset 0 -1px 0 #D4D4D4;
    color: #737373;
}

.form-field .bp3-html-select .bp3-icon, .form-field .bp3-select .bp3-icon, .form-field .bp3-select::after {
    top: 10px;
}

.form-field .bp3-html-select select:hover, .form-field .bp3-select select:hover {
    background-color: #FFFFFF;
}

/** SWITCH **/

.form-field .bp3-switch {
    display: inline-block;
    vertical-align: middle;
}

.form-field .bp3-switch .bp3-control-indicator {
    margin-left: 20px;
    margin-top: 3px;
}

.form-field .bp3-control.bp3-switch input:checked ~ .bp3-control-indicator {
    background-color: #00C6B2;
}

.form-field .bp3-control.bp3-switch:hover input:checked ~ .bp3-control-indicator {
    background-color: #00C6B2;
}

.form-field-switcher.no-label .bp3-switch {
    min-width:initial;
    padding-right:0;
}

.form-field-switcher .radio-label-with-help {
    display:flex;
    align-content:flex-end;
    justify-content:space-between;
}

/** DATE RANGE **/
.form-field.form-field-date-range-input .bp3-input-group {
    margin-right: 10px;
}

.form-field.form-field-date-range-input .bp3-control-group > .bp3-input-group {
    border-radius: 3px;
}

.bp3-datepicker {
    font-size:13px;
}

.bp3-datepicker .DayPicker-Day--disabledDays {
    opacity: 0.25;
    cursor: not-allowed;
}

.bp3-datepicker .DayPicker-Day.DayPicker-Day--selected, .bp3-datepicker .DayPicker-Day.DayPicker-Day--selected:hover {
    background-color: #008CFF;
}

/** DATE PERIOD SELECTOR **/
.form-period-display {
    font-size:15px;
    display:inline-block;
    margin: 0 10px;
}

/** SELECT **/
.form-field-select .bp3-button:not([class*="bp3-intent-"]):not(.bp3-disabled) {
    background: #FFFFFF;
    box-shadow: 0 0 0px 1px #D4D4D4;
}

.form-field-select .bp3-button.fixed-width {
    width: 270px;
    justify-content: space-between;
}

.form-field-suggest.suggest-clearable .bp3-input-group {
    margin-right:10px;
}

.inline-items .form-field-select .bp3-button {
    min-height: 38px;
}

/** NUMERIC INPUT **/

.form-field .bp3-numeric-input .bp3-button-group button {
    background: #FFFFFF;
}

.form-field .bp3-numeric-input .bp3-button-group .bp3-button.bp3-disabled {
    box-shadow: inset 0 0 0 1px rgb(16 22 26 / 10%), inset 0 -1px 0 rgb(16 22 26 / 10%);
}

.form-field .bp3-numeric-input.disabled-input-box .bp3-input:disabled {
    background: #ced9e07F;
    color: #5c708099;
    cursor: not-allowed;
    box-shadow: inset 0 0 0px 1px #D4D4D4;
}

.form-numeric-icon {
    position: absolute;
    left: 0;
    z-index: 55;
    margin: 5px 10px;
}

/** DAY MONTH SELECT **/
.form-field.form-field-select.form-field-day-month-select .bp3-button.fixed-width {
    width: 135px;
}

.bp3-datepicker-caption select + .bp3-icon {
    right: 2px !important;
}


/** PASSWORD CONFIRM **/

.password-confirm .bp3-input-group {
    max-width:270px;
}


/** GROUPED CHECKBOXES **/

.checkbox-list-container {
    max-width: 800px;
    max-height: 450px;
    width: 100%;
    overflow: auto;
}

.form-field-grouped-checkbox .checkbox-list-container {
    max-height:unset;
}


.form-field-grouped-checkboxes .grouped-checkbox-header {
    padding: 5px;
}

.form-field-grouped-checkboxes .form-field-grouped-checkbox:nth-child(odd) .grouped-checkbox-header {
    background-color: #F5F7F9;
}


    .form-field-grouped-checkboxes .grouped-checkbox-header h4 {
        margin:0;
    }

    .form-field-grouped-checkboxes .grouped-checkbox-header h4 .bp3-icon {
        vertical-align:middle;
    }

.form-field-grouped-checkboxes .grouped-checkbox-list-container .form-field {
    margin-bottom: 0;
}

.form-field-grouped-checkboxes .grouped-checkbox-list-container :nth-child(odd) .grouped-checkbox-list .form-field.checkbox-list.grouped label:nth-child(even),
.form-field-grouped-checkboxes .grouped-checkbox-list-container :nth-child(even) .grouped-checkbox-list .form-field.checkbox-list.grouped label:nth-child(odd),
.form-field.checkbox-list.striped label:nth-child(odd) {
    background-color: #F5F7F9
}

.form-field-grouped-checkboxes .grouped-checkbox-list .form-field label {
    margin-bottom: 0;
    padding: 5px 60px;
}

.form-field.checkbox-list.striped label.bp3-control {
    margin: 0;
    padding: 7px;
    padding-left: 35px;
}

.grouped-checkbox-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0 5px 5px;
}

.grouped-checkbox-list {
    margin-left:25px;
}

.checkbox-list-single-click, .checkbox-list-single-click label {
    cursor: pointer;
}

.grouped-checkbox-row:hover {
    background-color: #F5F7F9;
}

    .grouped-checkbox-row h4, .grouped-checkbox-row .bp3-control {
        margin-bottom: 0;
    }

.form-field-grouped-checkboxes .grouped-checkbox-list-container {
    display:inline-block;
    padding: 10px;
    border-radius: 3px;
    box-shadow: inset 0 0 0px 1px #d4d4d4;
    max-width:100%;
}

.form-field-grouped-checkboxes .grouped-checkbox-list-container .grouped-checkbox-list-scroll {
    max-width:100%;
    width:500px;
    max-height:450px;
    overflow:auto;
}

.grouped-checkbox-list-container .dropdown-list-scroll {
    max-width: 100%;
    width: 500px;
    max-height: 40vh;
    overflow: auto;
}

.grouped-checkbox-list-container .grouped-checkbox-select-count {
    display: inline-block;
    margin-left: 10px;
    color: #737373;
}

.grouped-checkbox-list-button .bp3-button-text {
    min-width: 155px;
}

.location-selector-search-container {
    padding: 8px;
    height: 50px;
}

/** MULTI SELECT **/
.form-field-multi-select .bp3-popover {
    overflow-x: auto;
    max-height: 200px;
}

.form-field-multi-select .bp3-tag {
    color: #FFF;
    font-size: 14px;
    background-color: #008CFF;
}

.form-field-multi-select .bp3-tag-input {
    min-width: 270px;
}

.form-field-red {
    color: #EC2D6D;
}

.form-field-amber {
    color: #FF9E00;
}

.form-field-green {
    color: #00C6B2;
}

.form-field .cell-percentage::after {
    content: '%';
}
.form-field-inline-switcher .form-field-switcher.no-label .bp3-switch .bp3-control-indicator {
    margin-right: 0;
    margin-left: 0;
}

.form-field-inline-switcher-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 280px;
}

.form-field-inline-switcher-label {
    font-size: 12px;
}

div .field-combination > .form-field {
    margin-bottom: 20px;
}

.bp3-input-group.bp3-large .bp3-input {
    font-size:14px;
}

.bp3-button.bp3-minimal {
    box-shadow: none !important;
}