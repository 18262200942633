div.info-panel {
    position: relative;
}
.info-panel-header {
    color: #212026;
    font-weight: bold;
    font-size: 16px;
}
.info-panel-content {
    padding: 10px;
    background-color: white;
    border-radius: 5px;
}
.info-panel-content h4 {
    font-weight: bold;
    font-size: 14px;
    color: #212026;
    text-transform: capitalize;
}
.info-panel-button {
    position: absolute;
    top:7px;
    right:0;
}
.info-panel-table {
    width: 100%;
}
.info-panel-table th {
    text-align: left;
    text-transform: uppercase;
    width: 25%;
    font-size: 11px;
    font-weight: bold;
    color: #212026;
    vertical-align: top;
}
.info-panel-table td {
    font-size: 22px;
    font-weight: bold;
    color: #212026;
    padding: 5px;
}

.info-panel-disabled {
    width: 500px;
    height: 200px;
}

.bp3-tab-indicator-wrapper
{
    background-color: #FFFFFF;
}

.bp3-tab[aria-selected="true"] div.panel
{
    background-color: #F0F0F0;
    box-shadow: 0 1px 6px 0 rgba(12, 34, 53, 0.18) !important;
}
div.vu-activity-name{
    display: inline-block;
    vertical-align: middle;
    margin: 0px;
}
img.vu-activity-icon-small {
    height: 15px;
    padding-right: 5px;
    display: inline-block;
    vertical-align: middle;
}
div.info-panel-comment .form-field {
    width: 100%;
}
div.info-panel-comment .form-field input[type=text] {
    width: 100% !important;
    max-width: 100% !important;
}