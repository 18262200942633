    #alerts-listing-container table {
        width: 60% !important;
    }

        #alerts-listing-container table th:first-child {
            min-width:400px;
        }

        #alerts-listing-container table th:last-child {
            width: 160px;
        }