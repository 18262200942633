div.harsh-braking-panel {
    position: relative;
}
.harsh-braking-header {
    color: #212026;
    font-size: 16px;
    padding-left: 5px;
}
.harsh-braking-header span.bp3-icon {
    display: inline-block;
    padding-left:5px;
    padding-bottom:2px;
    color: #1C8CF9;
}
.harsh-braking-content {
    padding: 10px 10px 1px 10px;
    background-color: white;
    border-radius: 5px;
}
.harsh-braking-panel-item {
    cursor: pointer;
}
.harsh-braking-panel-item h4 {
    font-weight: bold;
    font-size: 14px;
    color: #212026;
    text-transform: capitalize;
}
.harsh-braking-panel-item-table {
    width: 100%;
    background-color: white;
}
.harsh-braking-panel-item-table th {
    text-align: left;
    text-transform: uppercase;
    width: 33%;
    font-size: 11px;
    font-weight: bold;
    color: #212026;
}
.harsh-braking-panel-item-table td {
    font-size: 22px;
    font-weight: 600;
    color: #212026;
    padding: 5px;
}