.active-status.bp3-intent-danger {
    background: #EC2D6D;
    color: #FFFFFF;
}

.active-status.bp3-intent-success {
    background: #00C6B2;
    color: #FFFFFF;
}

.tag-list .bp3-tag {
    margin: 0 5px 5px 0;
}

.bp3-tag.status-block {
    font-weight: bold;
}

.bp3-tag.status-block.hollow {
    border-width: 2px;
    border-style: solid;
}

.bp3-tag.status-block.not-bold {
    font-weight: normal;
}

.circle-status {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    justify-content: center;
}

.circle-status i {
    color: #FFFFFF;
}

.circle-status.hollow {
    border-width: 2px;
    border-style: solid;
}

td .circle-status {
    height: 15px;
    width: 15px;
    margin: 3px 5px 0;
}

.status-block.dark {
    color: #3e3e3e;
}

.status-block {
    color: #f5f8fa;
}

.status-trend {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items:center;
}

.status-trend .bp3-icon {
    margin-left: 5px;
}

.status-trend.status-trend-down {
    color: #00c6B2 !important;
}

.status-trend.status-trend-up {
    color: #Ec2D6d !important;
}

.status-trend.status-trend-even {
    color: #4C5960 !important;
}