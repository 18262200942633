.bp3-tabs.bp3-vertical .bp3-tab-list {
    width: 100%;
}

    .bp3-tabs.bp3-vertical .bp3-tab-list .bp3-tab, .bp3-tabs.bp3-horizontal .bp3-tab-list .bp3-tab {
        border-radius: 4px;
        box-shadow: 0 1px 6px 0 rgba(12,34,53,0.18) !important;
        margin-bottom: 10px;
        padding: 5px;
        text-align: center;
        color: #0C2235;
        font-weight: 600;
    }

        .bp3-tabs.bp3-horizontal .bp3-tab-list .bp3-tab {
            padding: 5px 20px;
            min-width:200px;
        }



.bp3-tabs.bp3-horizontal .bp3-tab-list {
    flex-wrap: wrap;
}

            .bp3-tabs.bp3-vertical .bp3-tab-list .bp3-tab[aria-selected="true"], .bp3-tabs.bp3-horizontal .bp3-tab-list .bp3-tab[aria-selected="true"] {
                background-color: #0C2235 !important;
                color: #ffffff;
            }

.bp3-tabs.bp3-horizontal .bp3-tab-list .bp3-tab.tab-disabled {
    background: #F0F0F0 !important;
    color: #9DA2A6 !important;
    cursor: default;
}

.bp3-tabs.bp3-vertical > .bp3-tab-list li {
    width: 100%;
}

.bp3-tab-child-menu {
    margin: 15px 0;
}

.bp3-tab-child-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.bp3-tab-child-menu ul li {
    color: #0C2235;
    display: block;
    padding: 3px 13px;
    font-weight: 600;
    margin: 10px 0px;
    font-size: 13px;
}

    .bp3-tab-child-menu ul li.active {
        border-left: 3px solid #078fff;
        padding-left: 10px;
    }

        .bp3-tab-child-menu ul li.active a {
            color: #078fff;
        }

        .bp3-tab-child-menu ul li a {
            color: #0C2235;
            text-decoration: none;
        }

.center-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}