.risk-breakdown-table table tr:last-child td {
    border-top: 2px solid #CCCCCC;
}

.risk-breakdown-table table tr td:nth-child(2), .risk-breakdown-table table tr td:nth-child(3), .risk-breakdown-table-summary table tr td:nth-child(2), .risk-settings-summary table tr td:nth-child(4) {
    width: 200px;
}

.risk-settings-summary table tr td:nth-child(3), .risk-settings-summary table tr td:nth-child(1) {
    width: 150px;
}

.risk-breakdown-table table tr td:nth-child(4) {
    width: 200px;
}

.risk-breakdown-table-summary tr:nth-last-child(3) td, .risk-breakdown-table-summary tr:last-child td {
    border-top: 2px solid #CCCCCC;
}

.risk-event-suppressed {
    color:#7F7F7F;
}

.risk-event-suppressed .risk-event-name {
    text-decoration: line-through;
}
