div.box-panel {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    border-radius: 5px;
    background-color: #FAFAFA;
    font-weight: 600;
    position: relative;
    border: 0px transparent;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space:nowrap;
}
div.box-panel-after-color-bar {
    padding-left: 10px;
}
div.box-panel-color-bar {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 100%;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    padding-right: 10px;
}
div.box-panel-content {
    padding-top: 10px;
    padding-bottom: 10px;
}

div.box-panel-content i.fa {
    margin-left: 5px;
    margin-bottom: 2px;
    color: #686868;
}
div.box-panel-selected {
    background-color: #F0F0F0 !important;
}