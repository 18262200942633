.filter-row {
    clear: both;
    background: #E9ECEF;
    padding: 10px;
    border-radius: 4px;
}

.filter-container {
    clear:both;
    padding:15px 0;
}