.assign-driver-modal{
    width: 95%;
    max-width: 1200px;
    height: 80vh;
    overflow-y: auto;
}
.assign-drivers-header{
    padding-top: 10px;
}
.bp3-transition-container {
    z-index: 2000;
}
.inline{
    display: flex;
    align-items: center !important;
}
.driver-header{
    padding-left: 5px;
    padding-right: 5px;
}
.driver-checkbox-cell{
    padding-left: 10px;
}

.circle-status {
    height: 15px;
    width: 15px;
}

.assign-driver-printout-section{
    padding-bottom: 20px;
}

.printout-section {
    margin-top: 15px;
    width: 50%;
    padding-top: 12px;
    padding-left: 10px;
}
#printout-listing{
    background-color: #FFFFFF;
    padding: 10px;
}
.printout-file {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin: 0px;
    font-weight: normal;
}
.printout-section  h3{
    padding-top: 10px !important;
}
.printout-delete {
    padding: 0px !important;
}

.awaiting-printout-section {
    color: #797C80;
    margin-top: 15px;
    background-color: #F5F5F5;
    height: 45px;
    width: 50%;
    position: relative;
    padding-left: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.awaiting-printout-section span {

    padding-right: 10px;
    padding-bottom: 2px;
}
.awaiting-printout-flag-wording {
    color: #212026;
}
.assign-drivers-table th {
    padding-left: 0px !important;
}
.assign-drivers-table td {
    padding: 0px !important;
}
.assign-drivers-table td .bp3-control-indicator {
    position: relative;
    top: 5px;
}