.note-container {
    margin-bottom: 50px !important;
}
.note-container .form-field .form-text-counter {
    max-width: 500px;
    width: 100%;
    text-align: right;
    font-size: 12px;
    color: #686868;
    position: fixed;
}

.note-button-delete {
    background-color: white !important;
    color: #f0578a !important;
    box-shadow: none !important;
}

    .note-button-delete:hover {
        background-color: #0C223529 !important;
    }