.calendar-cell {
    border: 1px solid #e0e0e0;
    height: 100px;
    width: 200px;
    margin-top: -1px;
    margin-left: -1px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.calendar-cell-select-week {
    border: 1px solid #e0e0e0;
    height: 100px;
    width: 30px;
    margin-top: -1px;
    margin-left: -1px;
    padding: 40px 0 0 5px;
}

.calendar-cell-selected {
    background-color: #E6ECF2 !important;
}

.calendar-cell-week-odd {
    background-color: #f5f5f5;
}

.calendar-cell-info {
    padding: 5px;
    font-size: 14px;
    width: 100%;
    height: 26px;
}

.calendar-cell-info-top {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
}

.calendar-cell-info-bottom {
    font-size: 11px;
    display: flex;
    align-items: center;
    flex-direction:row;
}

.calendar-cell-tooltip {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.calendar-cell-icons {
    vertical-align: middle;
    display: flex;
    flex-direction: row;
}

.calendar-cell-icons div {
    padding-left: 5px;
}

.calendar-cell-times {
    padding: 0 5px;
}

.calendar-cell-activity-square {
    width: 5px;
    height: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
}


.calendar-cell-activity-bar-container {
    position: absolute;
    height: 15px;
}

.calendar-cell-activity-bar {
    height: 15px;
    width: 100%;
}

    .calendar-cell-activity-bar.selected.single {
        border: 2px solid black;
    }

    .calendar-cell-activity-bar.selected.start {
        border-top: 2px solid black;
        border-bottom: 2px solid black;
        border-left: 2px solid black;
    }

    .calendar-cell-activity-bar.selected.middle {
        border-top: 2px solid black;
        border-bottom: 2px solid black;
    }

    .calendar-cell-activity-bar.selected.end {
        border-top: 2px solid black;
        border-bottom: 2px solid black;
        border-right: 2px solid black;
    }

.calendar-cell-activity {
    position: relative;
    top: -15px;
}

    .calendar-cell-activity .bp3-popover-wrapper {
        width: 100%;
    }

    .calendar-cell-activity .bp3-popover-target {
        width: 100%;
    }