.listing-key {
    display: flex;
}

.listing-key-padding {
    padding: 20px 0px 10px 0px;
}

.keys-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.key-wrapper {
    display: flex;
    margin-right: 20px;
    align-items: center;
}

.key-wrapper label {
    font-size: small;
}

.key-item.rounded {
    border-radius: 50%;
    box-shadow: none;
}

.key-item {
    border-style: solid;
    position: relative;
    border-radius: 1px;
}

.key-item-border{
    box-shadow: 0px 0px 0px 1px #FFFFFF, 0px 0px 0px 2px #D1D1D1;
}

.key-item.selectable {
    cursor: pointer;
}

.key-item.selectable:hover {
    box-shadow: 0px 0px 0px 1px #FFFFFF, 0px 0px 0px 2px #9DA2A6;
}

.key-item.selectable.selected {
    box-shadow: 0px 0px 0px 2px #0C2235;
}

.key-item.empty {
    border-color: #ffffff;
    box-shadow: 0px 0px 0px 1px #F1F1F1, 0px 0px 0px 2px #D1D1D1;
}

.key-item.loading {
    animation: 1000ms linear infinite alternate skeleton-glow;
}

.key-item-container {
    position: relative;
    margin-right: 10px;
    font-size: 0;
}

.listing-key.vertical {
    flex-direction: column;
    padding: 0;
    row-gap: 15px;
}

.key-item-container.hollow:after,
.key-item-container.radio:after,
.key-item-container.halfed:after {
    content: "";
    display: block;
    position: absolute;
    left: 3px;
    right: 3px;
    top: 3px;
    bottom: 3px;
    border-radius: 50%;
}

.key-item-container.radio:after {
    box-shadow: inset 0px 0 0px 3px #ffffff;

}

.key-item-container.hollow:after {
    box-shadow: inset 0px 0 0px 1000px #ffffff;
}

.key-item-container.halfed:after {
    background: #FFFFFF;
    right: 50%;
    border-radius: 1000px 0 0 1000px;
}

.key-icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}