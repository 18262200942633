.status-header.inline-item {
    margin-bottom: 0;
}

.status-header-icon-container {
    height: 36px;
    width: 36px;
    min-width: 36px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.status-header-icon-container.smaller-icon {
    height: 30px;
    width: 30px;
    min-width: 30px;
    border-radius: 15px;
    padding-bottom: 4px;
    margin-right:16px;
}

.status-header-successful .status-header-icon-container {
    background: #00C6B2;
}

.status-header-failed .status-header-icon-container {
    background: #EC2D6D;
}

.status-header-default .status-header-icon-container {
    background: #D4D4D4;
}