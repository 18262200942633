.drag-drop {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-content: flex-start;
    align-items: flex-start;
}

.drag-drop-container {
    border: 2px dashed #d1d1d1;
    border-radius: 5px;
    width: 100%;
    position: relative;
    min-height: 125px;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}

    .drag-drop-container.disabled {
        opacity: 0.5;
        pointer-events: none;
    }

.drag-drop-item {
    user-select: none;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
    border-radius: 5px;
    background-color: #E0E0E0 !important;
    background-image: none;
    font-weight: 600;
    box-shadow: 0 1px 6px 0 rgb(12 34 53 / 18%) !important;
    color: black !important;
    cursor: default;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-flex;
    align-items: center;
    min-width: 60px;
    text-transform: uppercase;
}

.dragging-hidden {
    opacity: 0;
}

    .drag-drop-item.disabled {
        background-color: #D3D3D3 !important;
        cursor: not-allowed;
    }

    .drag-drop-item:hover {
        background-color: #CBCBCB !important;
    }


.drag-handle {
    cursor: grab;
    padding-bottom: 2px;
}

.drag-handle:active {
    cursor: grabbing;
}

.drag-drop-remove-button {
    background: none;
    border: none;
    color: black;
    font-size: 16px;
    cursor: pointer;
    z-index: 10;
    position: relative;
    margin-left: 5px;
}

.drag-drop-remove-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.item-text {
    margin-left: 8px;
    position: relative;
    top: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
