.associated-asset-table-col {
    margin: 0px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.associated-asset-table-checkbox {
    position: relative;
    top: 6px; 
}

.associated-asset-listing {
    display: flex;
    bottom: 20px;
    top: 270px;
    flex: 1;
    overflow-y: scroll;
}

.associated-asset-listing-no-filter {
    height: calc(90vh - 300px);
}

.associated-asset-listing-filter {
    height: calc(90vh - 380px);
}

    .associated-asset-listing > #listing {
        flex: 1;
    }