.note-button-delete {
    background-color: inherit !important;
}
.note-button-delete svg {
    color: #f0578a !important;
}
.operating-licence-collapseable-section{
    display: flex;
    margin-top: 40px;    
}


.operating-centre-overview-section {
    padding-top: 0px;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 0px;
    background-color: #f0f0f0;
    outline: none;
    margin-left: auto;
    max-height: 400px;
    max-width: 320px;
}

.operating-centre-overview-text {
    color: #797c80;
    line-height: 1.2;
}

.operating-centre-inner-card-style {
    overflow: hidden;
    max-height: 245px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.operating-centre-inner-card-kvp-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 8px;
    column-gap: 16px;
    margin-bottom: 50px;
}

    .operating-centre-inner-card-kvp:nth-child(3) {
        grid-column: span 2;
    }

    .operating-centre-inner-card-kvp h6 {
        margin-top: 10px;
        margin-bottom: 2px;
    }

    .operating-centre-inner-card-kvp div {
        margin-top: 0;
    }

.operating-centres-headers-contained-content {
    overflow-y: scroll;
}

.operating-centres-contained-content-auto {
    background:#ffffff;
    flex:1;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 400px;
}

#listing-table-operating-centres .listing-table, #listing-table-operating-centre-headers .listing-table {
    table-layout: fixed;
    width: 100%;
    max-height: 400px;
}

#listing-table-operating-centres > div > table > tbody > tr:nth-child(4n-1) td {
    background-color: white;
}

#listing-table-operating-centres > div > table > tbody > tr:nth-child(2n-1):hover td {
    background-color: #D1D1D1;
}

#listing-table-operating-centres > div > table > tbody > tr:nth-child(2n) td {
    display: none;
}

.operating-centres-clicked-row-style td {
    background-color: #E6ECF2 !important;
}

.operating-centres-clicked-inner-table-row-style td {
    display: table-cell !important;
}

.operating-centres-clicked-inner-table-row-style > td {
    padding: 0px !important;
    border: none !important;
}

.operating-centres-map-container {
    display: flex;
    flex-direction: column;
    width: 72%;
    height: auto;
    position: relative;
}

.operating-centre-map {
    position: relative;
}

.operating-centre-map-fullscreen {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    z-index: 100;
}


.operating-licence-favourite-icon{
    padding-bottom: 5px;
    padding-left: 7px;
}

.bp3-select-popover .operating-licence-favourite-icon {
    margin-top: -3px;
    margin-bottom: 0px;
    margin-left: -8px;
}