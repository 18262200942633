td.activities-listing-checkbox {
    padding-left: 85px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
}
td.activities-listing-checkbox .bp3-control-indicator {
    position: relative;
    top: 5px;
}
th.activities-listing-checkbox div {
    display: flex;
    align-items: top;
}
th.activities-listing-checkbox div label {
    margin-left: 4px;
    margin-bottom: 0px;
}

td.activities-listing {
    margin: 0px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    cursor: pointer;
}
th.activities-listing {
    padding-left: 0px !important;
    padding-right: 0px !important;
    cursor: pointer;
}
div.listing-left-bar {
    width: 8px;
    height: 23px;
    margin: 1px;
}
td.listing-left-bar {
    padding: 0px !important;
    width: 5px;
}
img.vu-activity-icon {

    height: 15px;
    margin-left: 5px;
    margin-right: auto;
    filter: opacity(0.55);
    display: block;
}
div.activity-list-icon {
    display: inline-block;
    width: 24px;
    height: 12px;
    margin: 0px;
}


div.activity-list-name {
    display: inline-block;
    padding-left: 15px;
}

tr.activity-list-highlighted {
    background-color: #E6ECF2;
}
#activities-tab-activities-list th {
    text-align: left;
    padding-left: 0px;
}
#activities-tab-activities-list th.listing-left-bar {
    padding-left: 10px;
}
