th.history-tab-table-item {
    width: 25%;
}
td.history-tab-table-item {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}
td.history-tab-table-item p {
    margin-bottom: 0px !important;
}
#listing-table-fleet-history div.list-table-no-results {
    margin-top: 20px !important;
}
#listing-table-fleet-history div.list-table-no-results h4{
    font-size: 16px !important;
}