.ol-stat-header {
    font-weight: 600;
    font-size: 11px;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.ol-stat {
    font-weight: 600;
    font-size: 19px;
}

.ol-stat-large {
    font-size: 26px;
}

.ocrs-green {
    color:#33D1C1;
    font-weight: 600;
}

.ocrs-amber {
    color: #FB9076;
    font-weight: 600;
}

.ocrs-red {
    color: #F0578A;
    font-weight: 600;
}

.ocrs-grey {
    color:#797C80;
    font-weight: 600;
}

.transport-manager-header {
    margin-top: 40px;
}

.new-transport-manager-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
    column-gap: 16px;
}

.transport-manager-grid-kvp {
    min-height: 145px;
}

.transport-manager-modal-body .button-row {
    display: flex;
    justify-content: flex-end;
}

.transport-manager-modal .bp3-dialog-body {
    margin-bottom: 0px !important;
}

.add-transport-manager {
    padding-top: 10px;
    display: flex;
}

.transport-manager-top-row {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.add-operating-licence-object-card {
    background-color: #DEE2FD;
    height: 30px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 3px;
    margin-right: 10px;
}