.listing-table-filter-fields {
    margin-bottom: 20px;
    font-size: 14px;
}

    .listing-table-filter-fields .form-field {
        display: inline-block;
        margin-bottom: 0;
    }

    .listing-table-filter-fields.single-item .form-field {
        display: block;
        width: 300px;
    }

.listing-table-per-page.listing-table-filter-fields .form-field {
    margin: 0 10px;
}

.listing-table td .form-field {
    margin-bottom: 0;
}

.list-table-clickable-header {
    cursor: pointer;
}

.listing-table .table-header-small {
    width: 90px;
}

.listing-table-overflow {
    overflow-x: auto;
    white-space: nowrap;
}

.pagination {
    margin-top: 15px;
}

.pagination.pagination-inline {
    margin: 0;
}

.pagination .bp3-button.bp3-large {
    border: 1px solid #EFEFEF;
    border-radius: 5em;
    height: 33px;
    width: 33px;
    font-size: 13px;
    min-width: 33px;
    min-height: 33px;
    margin-right: 5px;
}

    .pagination .bp3-button.bp3-large.active {
        background: #5A70F3;
        color: #FFFFFF;
        box-shadow: 0 1px 6px 0 rgba(12,34,53,0.18);
    }

.pagination .bp3-button.bp3-minimal:disabled {
    opacity: 0.5;
}

.pagination .bp3-button.bp3-minimal:hover {
    background-color: #EFEFEF;
}

.pagination .bp3-button.bp3-large.active:hover {
    background: #5A70F3;
}

.pagination-page-select {
    max-height: 180px;
    overflow: auto;
    min-width: 90px;
}

    .pagination-page-select button.bp3-button.bp3-minimal {
        display: block;
        width: 100%;
    }


table.bp3-html-table.listing-table th {
    font-size: 13px;
    text-transform: uppercase;
}

table.bp3-html-table td {
    vertical-align: middle;
}

.list-table-no-results {
    margin: 50px 0;
}

.performance-rating {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: #FFFFFF;
    display: inline-block;
    font-size: 14px;
    padding: 0;
    font-weight: bold;
    margin-right: 2px;
    text-align: center;
}

table.bp3-html-table td .cell-good {
    color: #00C6B2;
}

table.bp3-html-table td .cell-danger {
    color: #ec2d6d;
}

table.bp3-html-table td .cell-rating-a {
    background-color: #388E3C;
}

table.bp3-html-table td .cell-rating-b {
    background-color: #4CAF50;
}

table.bp3-html-table td .cell-rating-c {
    background-color: #A5D6A7;
}

table.bp3-html-table td .cell-rating-d {
    background-color: #FFEB3B;
}

table.bp3-html-table td .cell-rating-e {
    background-color: #FFC107;
}

table.bp3-html-table td .cell-rating-f {
    background-color: #FF6F00;
}

table.bp3-html-table td .cell-rating-g {
    background-color: #F44336;
}

table.bp3-html-table td .cell-percentage-suffix::after {
    content: '%';
}

table.bp3-html-table td .cell-pound-prefix::before {
    content: '£';
}

table.bp3-html-table.listing-table .form-field {
    margin-bottom: 0;
}

table.bp3-html-table.listing-table .listing-table-top-level-header th {
    padding-bottom: 0;
}

.listing-table-add-row-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

table.bp3-html-table.listing-table .listing-table-buttons .bp3-button:not(:last-child) {
    margin-right: 5px;
}

table.bp3-html-table.listing-table .listing-table-button-header-3 {
    width: 125px;
}

table.bp3-html-table.listing-table .listing-table-button-header-2 {
    width: 90px;
}

table.bp3-html-table.listing-table .listing-table-button-header-1 {
    width: 45px;
}

table.bp3-html-table.listing-table th.listing-table-button-header {
    padding-top: 5px;
    padding-bottom: 5px;
}

table.bp3-html-table.listing-table th.listing-table-validity-header {
    width: 5px;
}

table.bp3-html-table.listing-table .listing-table-validity {
    padding-left: 0;
}

table.bp3-html-table.bp3-html-table-striped tbody tr.table-row-invalid td {
    background-color: #FBD5E2;
}

table.bp3-html-table td i[class*="custom-icon-cat-"] {
    width: 55px;
    display: block;
}

.nowrap-table th, .nowrap-table td {
    white-space: nowrap;
}

.wide-table {
    overflow-x: auto;
    padding-bottom: 15px;
}

.listing-table-scroll {
    overflow-x: scroll;
}
.listing-table-scroll-vertical {
    overflow-y: scroll;
}