.dashboard-container {
    position: relative;
    padding-top: 58.8%;
}

.dashboard-container iframe {
    position:absolute;
    top:0; 
    left:0;
    width:100%;
    height:100%;
    border:0;
}

.dashboard-container-border {
    border: 1px solid #D4D4D4;
}
