.contained-content {
    position:fixed;
    top:60px;
    left:64px;
    right:20px;
    bottom:0;
    overflow:hidden;
    padding: 0px 0 10px;
    display:flex;
    flex-direction: column;

}

.contained-content-static {
    flex: 0;
}

.contained-content-auto {
    background:#ffffff;
    flex:1;
    overflow: auto;
}

.wrapper {
    display: flex;
    justify-content: space-between;
    text-align: left;
}

.box{
    width: auto;
}

.weekend{
    color: #737373;
}

span.registration {
    font-size:14px;
}

.vehicle-icon {
    width: 50px;
    font-size: 0;
}

.vehicle-icon img {
    height: 19px;
}
.additional-column {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 15px;
}

td .additional-column {
    white-space: nowrap;
    width: 100px;
}

table.bp3-html-table td.calendar-day {
    padding-left: 0px;
    padding-right: 0px;
}
table.bp3-html-table th.calendar-day {
    padding-left: 0px;
    padding-right: 0px;
    min-width: 40px;
}
h5.key{
    margin-top: 0px;
    font-size: 16px;
}

h1 {
    margin-top: 15px;
}

td.calendar-day span.loading {
    animation: none !important;
    visibility: hidden;
    > span
    {
        > span
        {
            > div
            {
                > div
                {
                    animation: 1000ms linear infinite alternate skeleton-glow;
                    background-color: rgba(206, 217, 224, 0.2);
                    visibility:visible !important;
                }
            }
        }
    }
}

td.additional-column span.loading {
    animation: none !important;
    visibility: hidden;
    > div
    {
        animation: 1000ms linear infinite alternate skeleton-glow;
        background-color: rgba(206, 217, 224, 0.2);
        visibility:visible !important;
    }
}

td.base-column span.loading {
    animation: none !important;
    visibility: hidden;
    > div {
        animation: 1000ms linear infinite alternate skeleton-glow;
        background-color: rgba(206, 217, 224, 0.2);
        visibility:visible !important;
    }
}

td.calendar-day {
    padding: 0 !important;
}

td.calendar-day .key-item-container {
    margin-top:4px;
}

#listing-table-fleet .listing-table, #listing-table-fleet-headers .listing-table {
    table-layout: fixed;
    max-width: 100%;
    width: fit-content !important;
}

#listing-table-fleet table.bp3-html-table td {
    padding: 5px 11px;
}

#listing-table-fleet-headers th {
    box-shadow: inset 0 -1px 0 0 rgba(16, 22, 26, 0.15);
}

#listing-table-fleet td {
    box-shadow: none;
}