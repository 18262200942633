.sticky-table-header {
    display: flex;
    flex-direction: row;
    flex: 1 1 0px;
}

div.scrollable {
    overflow-y: scroll;
}

.sticky-table-header .data-table-header {
    flex: 1 1 0px;
    text-align: center;
    border: 1px solid #D4D4D4;
    border-left: 0;
    padding: 20px 10px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.data-table-highlight {
    background: #EFF1FE;
}

.sticky-table-header .data-table-header:first-child {
    border-left: 1px solid #D4D4D4;
}

.sticky-table-header .form-field {
    margin-bottom: 0;
}

.sticky-table-header .form-field h4 {
    display: none;
}

.sticky-table-header .form-field label {
    margin-bottom: 0;
}

.data-table {
    width: 100%;
    padding: 0;
    border-collapse: collapse;
    table-layout: fixed;
}

.data-table td {
    border: 1px solid #D4D4D4;
    padding: 8px;
    vertical-align: middle;
    font-size: 13px;
}

.data-table .primary-header-cell {
    background-color: #0C2235;
    border-color: #0C2235;
    color: #FFFFFF;
    text-align: center;
    padding: 10px;
}

.data-table td.feature-available {
    text-align: center;
    color: #00C6B2;
}

.data-table td.feature-unavailable {
    text-align: center;
    color: #EC2D6D;
}

.expandable-element {
    max-height: 500px;
    overflow: auto;
}

.data-table td .form-field {
    margin-bottom: 0;
}

.data-table td .form-field label {
    margin-bottom: 0;
}

.data-table .action-disabled td {
    background: #FBD5E2;
}

.data-table-header-small {
    width: 75px;
}

.data-table-header {
    width: auto;
    border: 1px solid transparent;
    border-collapse: collapse;
}
