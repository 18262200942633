.push-right {
    display: flex;
    flex: 1;
    justify-content: end;
}

.graph-size-outer {
    width: 100%;
}

.key-bottom {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
}

.graph-size-inner {
    height: 45vh !important;
}