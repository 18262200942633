.beta-features-wrapper.form-field {
    height: auto !important;
    overflow: visible !important;
}

.beta-features-wrapper .switchlist {
    height: max-content !important;
    overflow: visible !important;
}

.beta-features-wrapper .bp3-button {
    margin-top: 20px !important;
}