.bp3-tooltip {
    font-size: 13px;
    max-width:300px;
}

.bp3-tooltip ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.bp3-tooltip ul li {
    line-height:1.7em;
}