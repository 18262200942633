.summary-tab-list {
    padding-top:10px;
}
.summary-tab-list div.box-panel {
    color: #000000;
    line-height:15px
}
.summary-tab-item {
    margin-right: 10px !important;
}
#content {
    width: 100%;
    margin-top: 20px;
    padding-right: 30px;
}
#graph{
    width: 100%;
    height: 120px;
    max-height: 120px;
}
#listing {
    float: left;
    width: 50%;
}
#info-panels {
    float: left;
    width: 50%;
    padding-left: 5px;
}
.info-panel-margin-bottom{
    margin-bottom: 10px;
}
.bp3-tab-list
{
    flex-wrap: wrap;
}
.bp3-tab[aria-selected="true"] {
    box-shadow: none !important;
}
.activities-tab-buttons {
    padding-top: 5px;
    margin-bottom: 20px;
}

.asset-activities-tab-container {
    height: 100%;
}

.asset-activities-tab {
    position: absolute;
    display: flex;
    bottom: 20px;
    top: 400px;
}
    .asset-activities-tab > #listing {
        overflow-y: scroll;
        flex: 1;
    }

    .asset-activities-tab > #info-panels {
        overflow-y: scroll;
        flex: 1;
    }