.system-message{
    padding: 12px;
    color: #FFFFFF;
}

    .system-message .bp3-icon {
        color: #FFFFFF;
    }

    .system-message a {
        color: #FFFFFF !Important;
    }

.system-message-icon{
    padding: 0 10px 0 8px;
    margin-top: -3px;
}

.incident-colour {
    background-color: #F0578A;
}

.maintenance-colour {
    background-color: #FF9076;
}

.general-colour {
    background-color: #33A3FF;
}

.company-colour {
    background-color: #b3defd;
    color: #2f658d;
}

    .company-colour .bp3-icon {
        color: #2f658d;
    }

.row
.popover-content
{
    max-width: 100%;
}

.bp3-focus-disabled{
    overflow-x: hidden;
}

#home-messages {
    margin-bottom: 15px;
}

#home-welcome .helper-text {
    margin-bottom: 15px;
}

#home-welcome h1 {
    margin-bottom: 15px;
}

.widget-error {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

    .widget-error .bp3-non-ideal-state {
        display: flex;
    }

#widget-container {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.widget {
    width: 400px;
    max-width: 400px;
    min-height: 398px;
    background-color: #F0F0F0;
    margin: 15px;
    padding: 10px;
    border-radius: 5px;
}

.widget-header .bp3-button.bp3-intent-primary {
    padding: 0 10px;
    font-size: 14px;
    font-weight: 600;
    min-height: 0px;
}

    .widget .widget-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #212026;
        height: 38px;
    }

    .widget .widget-counters {
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        justify-content: center;
        margin-top: 10px;
        height: 100px;
    }

.widget-counters-headers, .widget-counters-stats {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    padding: 5px;
    text-transform: uppercase;
}

.widget-counter-header, .widget-counter-stat {
    display: flex;
    flex-grow: 1;
    margin: 10px;
    width: 100%;
}

.widget-stat {
    color: #212026;
    font-weight: 700;
}

.widget-counter-header h5 {
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 600;
    font-size: 13px;
}

.widget-counter-header {
    margin-bottom: 0;
}

.widget-counter-stat {
    margin-top: 0;
}

    .widget-counter-stat .widget-stat {
        font-size: 22px;
        font-weight: 500;
        line-height: 1em;
        margin-bottom: 0;
    }

.widget-graph {
    background-color: #FFFFFF;
    margin-top: 10px;
    padding: 20px 15px 15px 15px;
    border-radius: 5px;
    flex-grow: 1;
    height: 220px;
}

    .widget-graph .echarts-for-react {
        height: 180px !important;
    }

.widget-listing {
    background-color: #FFFFFF;
    margin-top: 10px;
    padding: 15px;
    border-radius: 5px;
    height: 220px;
    overflow: scroll;
}

.widget-listing-full {
    height: 330px !important;
}

.widget-listing-loading {
    overflow: auto !important
}

.widget-listing table {
    width: 100%;
}

        .widget-listing table thead tr th {
            padding-top: 0px;
        }

        .widget-listing table thead tr td {
            font-weight: 500;
            text-transform: uppercase;
            font-size: 11px;
            padding-bottom: 10px;
        }

        .widget-listing table tbody tr td {
            font-size: 14px;
            height: 30px;
            padding-top: 0px;
            padding-bottom: 0px;
        }

#widget-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90vw;
}

#widget-header h3 {
    margin: 0;
}
.modal-content {
    max-height: 80vh;
    padding: 1em;
}

.wideModal {
    width: 90% !important;
}

.popover-content {
    width: 90vw;
    max-width: 90vw;
    padding: 1em;
    max-height: 70vh;
    overflow-y: auto;
}

.scrollable-table {
    max-height: unset;
    overflow-y: visible;
    padding-top: 1em;
    overflow-x: hidden;
}

    .scrollable-table table {
        width: 100%;
        border-collapse: collapse;
    }

        .scrollable-table table thead,
        .scrollable-table table tbody {
            display: table;
            width: 100%;
        }

        .scrollable-table table tr {
            display: grid;
            grid-template-columns: 70% 2% 3% 7% 17%;
            align-items: center;
        }

        .scrollable-table table th,
        .scrollable-table table td {
            padding: 10px;
            overflow: visible;
            word-wrap: break-word;
            vertical-align: middle;
            display: flex;
            align-items: center;
            height: 100%;
        }
            .scrollable-table table th:last-child,
            .scrollable-table table td:last-child {
                word-wrap: break-word;
                word-break: break-all;
                white-space: normal;
                overflow: hidden;
            }


    .scrollable-table .status-icon {
        margin-top: 3px;
        margin-bottom: -3px;
    }

        .scrollable-table table th {
            font-weight: bold;
        }

        .scrollable-table table thead tr {
            display: grid;
            grid-template-columns: 70% 2% 3% 7% 17%;
        }

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.form-field .bp3-input-group.ultra-wide input[type=text],
.form-field .bp3-input-group.ultra-wide input[type=password] {
    width: 90vw !important;
    box-sizing: border-box;
    display: block;
}

.sticky {
    position: fixed !important;
    height: 5em;
    top: 65px;
    z-index: 1;
}

.highlight {
    background-color: #9aa1a7;
}

.search-container {
    display: flex;
    padding-top: 21px;
}

.remove-button {
    background: none;
    border: none;
    color: white !important;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    padding-top: 12px;
    position: relative;
    right: 25px;
}
.remove-button.active {
    color: grey !important;
}

.search-input {
    position: absolute;
    width: 100%;
}

.configure-prefs-button {
    margin-right: 6vw !important;
}

.below-search {
    margin-top: 6.95em;
}

.search-row * {
    max-width: 100% !important;
}