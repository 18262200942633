#login-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(https://logukssotemplates.z33.web.core.windows.net/assets/bg-login.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    font-family: 'Open Sans', sans-serif;
    color: #555;
    overflow:auto;
}

#login-nav {
    background: #363940;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 8px 16px;
}

#login-nav.developer {
    background: #5A70F3;
}

#login-nav.test {
    background: #EC2D6D;
}

#login-nav.UAT, #login-nav.uat {
    background: #008CFF;
}

#login-nav .logo {
    max-width: 120px;
}

#login-container {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
}

    #login-container .panel {
        min-height: 300px;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 55px;
        border: 1px solid #D4D4D4;
        width: 100%;
        max-width: 500px;
        margin: 80px 0;
    }

    #login-container .links {
        margin-top: 40px;
        text-align: center;
        font-size: 13px;
    }

        #login-container .links a {
            color: #008CFF;
            text-decoration:none;
        }

    #new-policy{
        margin-right: 20px;
    }