.wizard-steps {
    width: 60%;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    padding: 15px 0;
    overflow: hidden;
    scroll-behavior: smooth;
    border-radius: 5px;
}

@media only screen and (max-width: 768px) {
    .wizard-steps {
        overflow: scroll;
        scroll-behavior: auto;
    }

    .wizard-steps-container .wizard-step-scroll-container {
        display: none;
    }
}

.wizard-step {
    position: relative;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wizard-step:before {
    content: "";
    width: 18px;
    height: 18px;
    background: #D4D4D4;
    display: block;
    border-radius: 10em;
}

.wizard-step:after {
    position: absolute;
    top: 9px;
    left: calc(50% + 15px);
    right: calc(-50% + 15px);
    height: 2px;
    margin-top: -1px;
    background: #D4D4D4;
    content: "";
    display: block;
    border-radius: 10em;
}

.wizard-step .bp3-icon {
    position: absolute;
    top: 7px;
    left: calc(50% - 8px);
    color: #ffffff;
    margin-top: -6px;
}

.wizard-step-complete.wizard-step:before,
.wizard-steps:not(.wizard-complete) .wizard-step-highlight-line.wizard-step:after {
    background: #00C6B2;
}

.wizard-step-errors.wizard-step:before {
    background: #EC2D6D;
}

.wizard-step-current.wizard-step:before {
    background: #008CFF; 
}

.wizard-step:last-child::after {
    display: none;
}

.wizard-steps.wizard-steps-with-titles {
    width: 100%;
}

.wizard-steps-with-navigation .wizard-step:hover {
    cursor: pointer;
}

.wizard-steps-with-titles .wizard-step {
    min-width: 150px;
}

.wizard-steps.wizard-steps-minimal .wizard-step {
    min-width: 0;
}

.wizard-steps.wizard-steps-minimal .wizard-step .wizard-step-title {
    position: absolute;
}

.wizard-step-title {
    text-align: center;
    word-break: break-word;
    padding: 15px 5px 0px;
}

.wizard-steps-container {
    display: flex;
    margin-bottom: 35px;
}

.wizard-step-scroll {
    background-color: transparent !important;
}

.wizard-step-scroll:not(:hover) {
    box-shadow: none !important;
}

.wizard-step-scroll-container {
    display: flex;
}

.wizard-step-scroll-container.scroll-left {
    padding-right: 30px;
}

.wizard-step-scroll-container.scroll-right {
    padding-left: 30px;
}

.wizard-steps.bp3-skeleton {
    min-height: 100px;
    padding: 0;
}