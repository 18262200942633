* {
    outline: none !important;
}

html {
    overflow: scroll;
}

body {
    background: #ffffff;
    font-family: 'Open Sans', sans-serif;
    color: #212026;
    font-size: 16px;
}

#main-container {
    width: 1280px;
    max-width: 100%;
    min-height: 300px;
    margin: 30px auto 60px;
}

#main-container.full-width {
    width: 100%;
    padding-left: 44px;
    margin-top: 0px;
}

.row {
    padding: 0 20px;
}

.breadcrumbs {
    margin-bottom: 20px;
    font-weight: 600;
}

    .breadcrumbs .bp3-breadcrumb {
        text-transform: uppercase;
        font-size: 13px;
    }

h1 {
    font-size: 28px;
    font-weight: 600;
    margin: 0 0 1em;
}

h2 {
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 0.75em;
}

h3 {
    font-size: 21px;
    font-weight: 600;
    margin: 0 0 0.75em;
}

h4 {
    font-size: 16px;
    font-weight: 400;
    margin: 0 0 0.75em;
}

.layout-cols {
    font-size: 0;
}

.col {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
}

.side-col {
    width: 25%;
}

#main-container.full-width .side-col {
    max-width: 400px;
}

#main-container.full-width .layout-cols {
    display: flex;
}

#main-container.full-width .content-col {
    width: 100%;
    overflow: auto;
}

.content-col {
    width: 75%;
}

.col-grid {
    display: grid;
}

.panel {
    border: 1px solid #D4D4D4;
    padding: 15px;
    border-radius: 10px;
}

.no-margin{
    margin: 0 !important;
}

.spacer-bottom-large {
    margin-bottom:40px;
}

.spacer-bottom {
    margin-bottom: 30px;
}

.spacer-bottom-medium {
    margin-bottom: 20px;
}

.spacer-bottom-small {
    margin-bottom: 15px;
}

.spacer-top {
    margin-top: 30px;
}

.spacer-top-small {
    margin-top: 15px;
}

.spacer {
    margin-top: 30px;
    margin-bottom: 30px;
}

.spacer-small {
    margin-top: 15px;
    margin-bottom: 15px;
}

.spacer-left{
    margin-left: 20px;
}

.padded {
    padding: 15px;
}

.para-text {
    max-width: 800px;
}

.intro-text {
    margin-bottom: 30px;
    line-height: 1.5em;
    color: #797C80;
}

    .intro-text p:last-child {
        margin-bottom: 0;
    }

.description-text {
    line-height: 1.5em;
    color: #737373;
    margin-bottom:0;
}

.justify-left {
    justify-content: flex-start;
}

.justify-right {
    justify-content: flex-end;
}

.bold {
    font-weight: 600;
}

.bp3-menu-item.bp3-intent-primary:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item, .bp3-menu-item.bp3-intent-primary.bp3-active {
    background-color: #008CFF;
}

.bp3-input {
    box-shadow: inset 0 0 0px 1px #D4D4D4;
}

    .bp3-input:focus, .bp3-input.bp3-active {
        box-shadow: 0 0 0px 1px #D4D4D4;
        border: 1px solid rgba(0,140,255,0.5);
    }

.bp3-select-popover, .dropdown-list-behaviour {
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
}

.bp3-list li:not(:last-child) {
    margin-bottom: 10px;
}

.bp3-icon.bp3-intent-success, .bp3-icon-standard.bp3-intent-success, .bp3-icon-large.bp3-intent-success {
    color: #00C6B2;
}

.bp3-tag.bp3-intent-success {
    background-color: #00C6B2;
}

.inline-item {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
}

.inline-item.fill {
   flex: 1;
}

.inline-items {
    display: flex;
    align-items: center;
}

.inline-items .inline-divide {
    height:30px;
    width:0px;
    border-right:1px solid #d1d1d1;
}

.align-items-flex-start {
    align-items: flex-start !important;
}

.align-items-stretch {
    align-items: stretch !important;
}

.text-align-center {
    text-align: center;
}

.text-align-right {
    text-align:right;
}

.inline-items-space {
    justify-content: space-between;
}

.inline-items-space-reverse {
    justify-content:space-between;
    flex-direction: row-reverse;
}

.inline-items-fill > .inline-item {
    flex:1;
}

.inline-items-center {
    justify-content: space-evenly;
}

.inline-items-end {
    justify-content: end;
}

    .inline-items .form-field, .inline-items h1, .inline-items h2, .inline-items h3, .inline-items h4 {
        margin-bottom: 0;
    }

.inline-items > * {
    margin-right: 10px;
}

.inline-items.space-large > * {
    margin-right: 15px;
}

.inline-items > *:last-child {
    margin-right: 0;
}

.inline-items.no-spacing > * {
    margin-right: 0;
}

@media only screen and (max-width: 1370px) {
    body {
        padding-left: 45px;
    }
}

@media only screen and (max-width: 1000px) {
    .side-col {
        width: 35%;
    }

    .content-col {
        width: 65%;
    }
}

@media only screen and (max-width: 768px) {
    .side-col {
        width: 100%;
        margin-bottom: 30px;
    }

    .content-col {
        width: 100%;
    }

    .pull-none-mobile {
        float: none;
    }
}

.switchlist .form-field {
    margin-bottom: 5px;
}

    .switchlist .form-field label {
        width: 400px;
    }

.switchlist {
    max-height: 230px;
    overflow: auto;
    width: 450px;
}

.break-word {
    word-break: break-word;
}

/* Style the scroll bar */
*::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

*::-webkit-scrollbar-track {
    background: white;
}

*::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 20px;
    border: 3px solid white;
}

.bp3-non-ideal-state {
    display: block;
    text-align: left;
}

.bp3-non-ideal-state .bp3-heading {
    margin-bottom:1em;
}


.bp3-non-ideal-state p {
    line-height:1.6em;
}

.bp3-non-ideal-state-visual {
    color: #D4D4D4;
    
}

.non-ideal-center .bp3-non-ideal-state-visual {
    text-align: center;
}

.bp3-non-ideal-state > div {
    max-width: 800px !important;
}

.bp3-non-ideal-state.wide > * {
    max-width: 800px !important;
}

.show-in-print {
    display: none;
}

.nowrap {
    white-space: nowrap
}

.preserve-white-space {
    white-space: pre-line;
}

.margin-up {
    margin-top: -15px;
}

.cursor-pointer {
    cursor: pointer;
}

.no-select {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}