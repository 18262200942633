.operating-licence-assets-over-limit {
    color: #F0578A;
}
.operating-licence-icon-column {
    text-align: center;
}

.operating-licences-info-icon {
    transform: scale(0.8);
}

.favourite-header-text{
    padding-right: 3px;
}

.favourite-header-wrapper {
    display: flex;
}

.operating-licences-favourited-row-style td {
    background-color: #E6ECF2 !important;
}

.add-operating-licence{
    display: flex;
    align-items: baseline;
}

.add-operating-licence-button{
    margin-right: 10px !important;
}