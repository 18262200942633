.operating-licence-map-container {
    width: 100%;
    height: 100%;
    position: relative;
}

.bp3-dialog.full-screen.operating-licence-map-modal {
    padding: 0;
    position: relative;
    overflow-x: hidden;
}

    .bp3-dialog.full-screen.operating-licence-map-modal .bp3-dialog-body {
        margin: 0;
    }

.operating-licence-map-modal .bp3-dialog-header {
    position: absolute;
    top: 0;
    right: 0.5%;
    z-index: 10;
    background-color: transparent;
}

.operating-licence-map-modal .operating-centre-overview-section {
    position: absolute; 
    bottom: 20px;
    right: 20px;
    z-index: 10;
}