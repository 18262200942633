#hierarchy-container {
    display: flex;
}

.hierarchy-placeholder {
    background-color: #E9ECEF;
    min-height: 50px;
    width: 100%;
}

    .hierarchy-placeholder > .hierarchy-item {
        margin-bottom: 0;
    }

.hierarchy-item {
    padding: 10px;
    background-color: #E9ECEF;
    margin-bottom: 10px;
}

    .hierarchy-item.item-striped {
        background-color: #FFFFFF;
    }

.hierarchy-title-row {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.hierarchy-title {
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0;
}

.hierarchy-title-info {
    display: inline-block;
    margin-left: 10px;
    color: #737373;
}

.hierarchy-item-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.hierarchy-drop {
    min-height: 70px;
    border: 2px dashed #b3b3b3;
    position: relative;
    width: 100%;
    padding: 10px;
    max-height: 120px;
    overflow: auto;
}

    .hierarchy-drop.drop-empty::after {
        content: "Drag a location from the left to start adding locations to this group";
        text-transform: uppercase;
        font-weight: 600;
        position: absolute;
        left: 0;
        top: 50%;
        font-size: 13px;
        color: #7f7f7f;
        width: 100%;
        margin-top: -8px;
        padding: 0 20px;
        text-align: center;
    }

    .hierarchy-drop.drop-hover {
        background-color: #F0F8FF;
    }

.hierarchy-item .bp3-button-text, .hierarchy-item .bp3-button .bp3-icon, .hierarchy-item .call-to-action {
    color: #212026;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600
}

.hierarchy-item .bp3-button.bp3-minimal:disabled .bp3-button-text {
    color: rgba(92, 112, 128, 0.6);
}

.hierarchy-item .call-to-action {
    margin: 15px 0 5px;
}

.hierarchy-drop .hierarchy-location-item {
    user-select: none;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 5px;
    margin: 0 5px 5px 0;
    display: inline-block;
    transform: none !important;
    background-color: #FFFFFF;
    background-image: none;
    font-weight: 600;
    box-shadow: 0 1px 6px 0 rgb(12 34 53 / 18%) !important;
    padding-left: 15px;
    position: relative;
}

.hierarchy-location-item:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 100%;
    background: #CCCCCC;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}


    .hierarchy-location-item.tacho-loc:before {
        background: #00C6B2;
    }

    .hierarchy-location-item.vis-loc:before {
        background: #5A70F3;
    }

.hierarchy-location-item.pcn-loc:before {
    background: #EC2D6D;
}

.hierarchy-location-item.audit-loc:before {
    background: #FF7454;
}

.hierarchy-location-item.tacho-vis-loc:before {
    background-image: linear-gradient(0deg, #00C6B2 0% 50%, #5A70F3 50% 100%);
}

.hierarchy-location-item.tacho-audit-loc:before {
    background-image: linear-gradient(0deg, #00C6B2 0% 50%, #FF7454 50% 100%);
}

.hierarchy-location-item.tacho-pcn-loc:before {
    background-image: linear-gradient(0deg, #00C6B2 0% 50%, #EC2D6D 50% 100%);
}

.hierarchy-location-item.vis-pcn-loc:before {
    background-image: linear-gradient(0deg, #5A70F3 0% 50%, #EC2D6D 50% 100%);
}

.hierarchy-location-item.vis-audit-loc:before {
    background-image: linear-gradient(0deg, #5A70F3 0% 50%, #FF7454 50% 100%);
}

.hierarchy-location-item.audit-pcn-loc:before {
    background-image: linear-gradient(0deg, #FF7454 0% 50%, #EC2D6D 50% 100%);
}

.hierarchy-location-item.tacho-vis-audit-loc:before {
    background-image: linear-gradient(0deg, #00C6B2 0% 33%, #5A70F3 33% 66%, #FF7454 66% 100%);
}

.hierarchy-location-item.tacho-vis-pcn-loc:before {
    background-image: linear-gradient(0deg, #00C6B2 0% 33%, #5A70F3 33% 66%, #EC2D6D 66% 100%);
}

.hierarchy-location-item.tacho-audit-pcn-loc:before {
    background-image: linear-gradient(0deg, #00C6B2 0% 33%, #FF7454 33% 66%, #EC2D6D 66% 100%);
}

.hierarchy-location-item.vis-audit-pcn-loc:before {
    background-image: linear-gradient(0deg, #5A70F3 0% 33%, #FF7454 33% 66%, #EC2D6D 66% 100%);
}

.hierarchy-location-item.tacho-vis-audit-pcn-loc:before {
    background-image: linear-gradient(0deg, #00C6B2 0% 25%, #5A70F3 25% 50%, #FF7454 50% 75%, #EC2D6D 75% 100%);
}

.hierarchy-location-item.inactive-loc {
    color: #8d8d8d;
}

.delete-location {
    cursor: pointer;
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
}

    .delete-location.delete-location-disabled {
        color: rgba(92, 112, 128, 0.6);
        cursor: not-allowed;
    }