.calendar-preferences-modal-button {
    align-self: flex-end;
    margin-top: 10px;
    flex-direction: column !important;
}

.calendar-preferences-modal .bp3-heading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 24px;
}

.calendar-preferences-modal .calendar-preferences-modal-header {
    font-size: 16px !important;
    font-weight: bold;
}

.calendar-preferences-modal .spacer-bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 0px;
}

.calendar-preferences-modal .form-field {
    margin-right: 100px;
}

.calendar-preferences-modal.bp3-dialog {
    height: 340px;
    width: 620px;
}

.calendar-preferences-modal .bp3-dialog-body {
    margin-bottom: 0px;
}

.calendar-preferences-modal .bp3-control {
    margin-top: 12px;
}

.calendar-preferences-modal-wrapper {
    display: inline-flex;
}

.calendar-preferences-modal-disable-close .bp3-dialog-close-button {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed !important;
}
